<!-- 生成二维码 -->
<template>
  <div class="wrap">
    <div class="img-canvas" v-loading="loading">
      <canvas ref="company_code"></canvas> 
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { agent_company_code }  from "@/api/agent/index.js"; 
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
 

    //canvas
    createdImg(dom, bgImageUrl, codeUrl,company_name) {
      var arr = [];
      arr.push(this.loadImg(bgImageUrl));
      arr.push(this.loadImg(codeUrl));
      arr.push(company_name);

      var that = this;
      Promise.all(arr).then(function (arr) {
        console.log(arr);
        that.draw(dom, arr);
      });
    },
    //promise
    loadImg(src) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.src = src;
        img.onload = function () {
          //加载成功
          resolve(img);
        };
        img.onerror = function () {
          //加载失败
          reject("加载错误");
        };
      });
    },
    //绘制canvas
    draw(dom, arr) {
      var canvas = this.$refs[dom];

      canvas.width = 300;
      canvas.height = 500;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(arr[0], 0, 0, 300, 500);
      ctx.drawImage(arr[1], 75, 250, 150, 150);

      ctx.fillStyle='#ffffff';
      ctx.font='12px Arial';
      ctx.fillText("欢迎加入"+arr[2],10,480)


    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
      //判断是否有独立管理经纪人权限
      if (sessionStorage.getItem("is_gl_agent") != 1) {
      return this.$confirm("您没有管理权限，请联系管理员！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push({
            path: "/company",
          });
        })
        .catch((e) => {
          this.$router.push({
            path: "/company",
          });
        });
    }

    
    console.log("生命周期sssssssssdddddddddd");
    agent_company_code({ }).then((res) => {
      console.log("sssssssssdddddddddd");
      console.log(res.data);

      if (res.code === 0) {
        this.loading = false;
        this.createdImg(
          "company_code",
          require("./../../assets/images/tool/bg_agent.jpg"),
          res.data.company_agent,
          res.data.company_name
        );
      }
    
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
}
.img-canvas {
  width: 1000px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  // background-color: red;

  min-height: 400px;
}
</style>